.content-title {
  display: inline-block;

  h2 {
    color: $emColor1;
    font-weight: 700;
    text-transform: uppercase;
  }

  &::after {
    border-top: 3px solid $emColor1;
    content: '';
    display: block;
    margin-top: $smallMarginY;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 95%;
  }

  &--triangle {
    border-top: 15px solid $emColor1;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    content: "";
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
  }
}
