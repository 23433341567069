.header {
  margin-top: $largeMarginY;

  @include md {
    margin-top: $mediumMarginY;
  }

  &__logo {
    display: inline-block;

    img {
      height: auto;
      width: 200px;
    }
  }

  &__language {
    font-family: $emFontFamily;
    position: absolute;
    right: 15px;
    text-align: right;
    top: -35px;

    @include md {
      float: right;
      margin-right: $defaultMarginX;
      position: unset;
    }

    a {
      margin-left: $smallMarginX;
      margin-right: $smallMarginX;
    }

    .active {
      font-weight: 700;
    }

  }

  &__logo-sbh {
    float: right;

    img {
      float: right;
      height: auto;
      width: 100px;
    }
  }


  &__img {
    img {
      margin-top: -45px;

      @include md {
        margin-top: -80px;
      }
    }
  }
}
