.content-section {
  border: 1px solid;
  border-top: none;
  letter-spacing: 0.05em;
  margin-bottom: $largeMarginY;
  margin-top: $largeMarginY; // should be 40px, is 45px
  padding-bottom: $mediumPaddingY; // should be 70px, is 60px

  &__title {
    font-size: $xLargeFontSize;
    font-weight: 700;
    padding-top: $mediumPaddingY; // should be 70px, is 60px
    text-align: center;
    text-transform: uppercase;
  }

  &__subtitle {
    color: $wColor;
    display: inline-block;
    font-size: $xLargeFontSize;
    font-weight: 700;
    text-transform: uppercase;
    padding: $xsmallPaddingY $defaultPaddingX;
    margin-left: -15px; // To remove bootstrap col padding
    margin-top: $mediumMarginY; // should be 47px, is 30px
  }

  &__map {
    margin-bottom: $mediumMarginY;

    h3 {
      font-weight: 700;
      margin-top: $largeMarginY;
      text-align: center;
      text-transform: uppercase;
    }

    ul {
      list-style-type:disc;
      margin-top: $defaultMarginY;
    }
  }

  &__warning {
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    img {
      height: auto;
      width: 75px;
    }
    p {
      margin-top: $defaultMarginY;

      @include md {
        margin-top: 0px;
      }
    }

  }

  &__array-intro {
    margin-bottom: $mediumMarginY;
    text-align: center;
  }

}
