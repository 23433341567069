.content-array {
  letter-spacing: 0px;
  width: 100%;

  &__title {
    border-radius: 16px;
    color: $wColor;
    font-weight: 700;
    letter-spacing: 0.05em;
  }

  tr {
    td,th {
      padding-bottom: 3px;
      padding-left: $defaultPaddingX;
      padding-right: $defaultPaddingX;
      vertical-align: middle;
    }
  }

  &__prices {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    text-align: right;
    width: 45%;

    @include lg {
      width: 32%;
    }
  }

  &__label {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}
