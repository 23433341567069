.footer-content {
  background-color: $emColor5;
  border-radius: 16px;
  font-size: $largeFontSize;
    padding: $mediumPaddingY $largePaddingX ; //Y should be 53px is 60px, X should be 87px, is 90px

  @include md {
    padding: $mediumPaddingY $xxLargePaddingX ; //Y should be 53px is 60px, X should be 87px, is 90px
  }

  a {
    text-decoration: none;
  }

  h2 {
    color: $wColor;
    font-weight: 700;
    text-transform: uppercase;
  }

  img {
    height: auto;
    margin-right: 5px;
    width: 25px;
  }

  &__hours {
    padding-bottom: $defaultPaddingY; // should be 40px, is 30px
    padding-top: $defaultPaddingY; // should be 40px, is 30px
    padding-left: $mediumPaddingX; // should be 35px, is 30px

    p:first-of-type, span {
      font-weight: 700;
    }
  }

  &__contact{
    color: $wColor;
    font-weight: 700;
    padding-bottom: $defaultPaddingY; // should be 40px, is 30px
  }
}
