$defaultPaddingX: 15px;
$defaultPaddingY: 30px;

$smallPaddingX: $defaultPaddingX / 2;
$mediumPaddingX: $defaultPaddingX * 2;
$largePaddingX: $defaultPaddingX * 3;
$xLargePaddingX: $defaultPaddingX * 4;
$xxLargePaddingX: $defaultPaddingX * 6;

$xsmallPaddingY: $defaultPaddingY / 3;
$smallPaddingY: $defaultPaddingY / 2;
$mediumPaddingY: $defaultPaddingY * 2;
$largePaddingY: $defaultPaddingY * 3;
$xLargePaddingY: $defaultPaddingY * 4;
