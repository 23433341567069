.color {
    &--1 {
        color: $emColor1 !important;
    }

    &--2 {
        color: $emColor2 !important;
    }

    &--3 {
        color: $emColor3 !important;
    }

    &--4 {
        color: $emColor4 !important;
    }

    &--w {
        color: $wColor !important;
    }
}

.color-br {
  &--2 {
      border-color: $emColor2 !important;
  }

  &--3 {
      border-color: $emColor3 !important;
  }
}

.color-bg{
    &--d-1 {
        background-color: $darkColor1 !important;
    }

    &--l-1 {
        background-color: $lightColor1 !important;
    }

    &--l-2 {
        background-color: $lightColor2 !important;
    }

    &--2 {
        background-color: $emColor2 !important;
    }

    &--3 {
        background-color: $emColor3 !important;
    }

    &--5 {
        background-color: $emColor5 !important;
    }

    // Responsives classes must be after to allow CSS priority
    &--md-d-1 {
        @include md {
            background-color: $darkColor1 !important;
        }
    }

    &--lg-d-1 {
        @include lg {
            background-color: $darkColor1 !important;
        }
    }
}
