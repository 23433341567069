.bordered-section {
  border: 2px solid;
  border-radius: 16px;
  margin-bottom: $largeMarginY;
  margin-top: $largeMarginY;
  padding-bottom: $smallPaddingY;
  padding-left: $defaultPaddingX;
  padding-right: $defaultPaddingX;

  &__title {
    background-color: white;
    display: inline-block;
    font-weight: 700;
    top: -15px;
    margin-right: $defaultMarginX; // should be 20px is 15px
    padding-left: $defaultPaddingX; // should be 20px is 15px
    padding-right: $defaultPaddingX; // should be 20px is 15px
    position: relative;
  }

  &__icons {
    padding-top: $defaultPaddingY; // should be 35px is 30px
    text-align: center;
    text-transform: uppercase;

    img {
      height: auto;
      margin-top: $smallMarginY;
      width: 100px;
    }

    &__item {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: $defaultPaddingY; // should be 40px is 30px
    }

  }

  &__content {
    text-align: center;

    a {
      font-weight: 700;
    }

    a {
      color: $emColor6;
      text-decoration: underline;
    }
  }
}
