/*! custom.css - Project style entry point */

/* ==========================================================================
   Author's custom styles - Follow BEM and Components CSS file used for helpers
   ========================================================================== */

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    width: 100%;
}

body {
    background-color: $backColor;
    color: $defaultTextColor;
    font-family: $defaultFontFamily;
    font-size: $defaultFontSize;
    font-weight: 300;
}

img,
video {
    width: 100%;
}

a,
a:hover,
a:visited,
a:focus {
    color: inherit;
}

a {
  cursor: pointer;
}

ul {
    padding: 0;
    list-style: none;
}

hr {
  border-top: 1px solid $emColor1;
}

*:focus {
  outline: none;
}

strong {
  font-weight: bold;
}


/* Media query imports - Follows BS notation*/
@include sm {

}

@include md {

}

@include lg {

}

@include xl {

}
