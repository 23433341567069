$defaultFontFamily: 'Oswald', sans-serif;
$emFontFamily: 'Roboto', sans-serif;

$defaultFontSize: 20px;
$em: $defaultFontSize * 1em;

$normalFontSize: 1em;
$smallFontSize: 0.75em;       //12px
$mediumFontSize: 1.25em;     //25px
$largeFontSize: 1.35em;     //27px
$xLargeFontSize: 1.5em;         //30px
$bigFontSize: 1.7em;            //34px

$mainMenuFontSize: 1em;       //20px
