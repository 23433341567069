.introduction{
  h1 {
    color: $emColor1;
    font-size: $bigFontSize;
    font-weight: 900;
    padding-bottom: $mediumPaddingY; // should be 65, is 60 for now
    padding-top: $defaultPaddingY; // should be 65, is 60 for now
    text-align: center;
    text-transform: uppercase;
  }

  &__content {
    background-color: $emColor5;
    border-radius: 16px;
    color: $wColor;
    font-size: $largeFontSize;
    font-weight: 300;
    padding: $defaultPaddingY $largePaddingX; // Y should be 28px is 30, X should be 52px is 45px
    margin-bottom: $mediumMarginY; // should be 30px is 35px
  }
}
