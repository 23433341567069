.content-header {
  padding: 0px;
  margin-left: -16px;
  margin-right: -16px;

  a {
    color: $wColor;

    &:hover {
      color: $wColor;
      cursor: default;

      &:not(.active) {
        cursor: pointer;
      }
    }
  }

  &__item {
    float: left;
    font-family: $emFontFamily;
    font-weight: 900;
    padding-bottom: $smallMarginY;
    padding-left: $defaultPaddingX;
    padding-right: $defaultPaddingX;
    padding-top: $smallMarginY;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @include md {
      width: 40%;
    }

    &.active {
      font-size: $xLargeFontSize;
      width: 100%;

      @include md {
        width: 60%;
      }
    }
  }
}
