$backColor: #ffffff;

$defaultTextColor: #333333;

// Black & White colors
$wColor: #ffffff;
$bColor: #000000;

// Theme colors
$emColor1: #20294E;
$emColor2: #007F85;
$emColor3: #A91B45;
$emColor4: #969A37;
$emColor5: #0087BB;
$emColor6: #005A78;

// Theme grays
$lightColor1: #CDCDCD;
$lightColor2: #E6E6E6;
$darkColor1: #111111;

$defaultButtonTextColor: #ffffff;
$defaultButtonBackColor: red;
$defaultButtonEmTextColor: red;
$defaultButtonEmBackColor: #ffffff;
